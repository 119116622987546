@media all and (max-width: 1660px) and (min-width: 701px) {
    .nav {
        
        .page-link {
            font-size: 1.5em;
        }
    }
}

@media all and (max-width: 1100px) and (min-width: 701px) {
    .nav-container {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        padding: 0px calc(10vw + 20px);
        max-height: 400px;
        // margin-top: 100px;
    }

    .nav {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    
        .page-link {
            font-size: 1.2em;
        }
    }

    .nav-footer {
        display: flex;
        margin-top: 20px;
    }

    .title {
        margin-bottom: 20px;
        text-align: left;
    }

    // .active::before { 
    //     content: "";
    // }

    // .active::after { 
    //     content: " • ";
    //     transition: all .2s ease-in-out;
    // }
}

@media all and (max-width: 700px) and (min-width: 300px) {

    .nav-container {
        height: 101vh;
        width: 100vw;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-flex: 0;
        -ms-flex: 0 auto;
        flex: 0 auto;
        padding: 0 0 16vh 3vw;
        overflow: hidden;
    }

    .nav {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    
        .page-link {
            font-size: 1.2em;
        }
    }

    .nav-footer {
        display: flex;
        margin-top: 20px;
    }

    .title {
        margin-bottom: 16px;
        text-align: left;
    }

    // .active::before { 
    //     content: "";
    // }

    // .active::after { 
    //     content: "";
    // }
}