@media all and (max-width: 1100px) and (min-width: 701px) {
    
}

@media all and (max-width: 700px) and (min-width: 300px) {
    .footer-container {
        position: absolute;
        top: 300%;
        display: none;
    }
}