.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
  height: 100vh;
  width: 100vw;
  color: rgb(209, 209, 209);
  background: rgb(77,77,77);
  background: radial-gradient(circle, rgba(57,57,57,1) 0%, rgba(0,0,0,1) 100%);
}
