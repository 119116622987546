* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(77,77,77);
  background: radial-gradient(circle, rgba(57,57,57,1) 0%, rgba(0,0,0,1) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0px;
  padding: 0px;
}

h1 {
  font-size: 2.6em;
}

h2 {
  font-size: 1.4em;
}

h3 {
  font-size: 1.4em;
}

h4 {
  font-size: 1.2em;
}

h5 {
  font-size: 1em;
}

a {
  text-decoration: none;
  color: inherit;
  margin: 0px;
  padding: 0px;

  &:hover {
    cursor: pointer;
  }

  /* &:hover {
    color: $link-hover-color-green;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
  }

  &:active {
    color: $link-hover-color-green;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
  }

  &:focus {
    color: $link-hover-color-green;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
  } */
}

p {
  font-size: 1em;
}

@media all and (max-width: 1660px) and (min-width: 1301px) {
  h1 {
    font-size: 2.2em;
  }

  h2 {
    font-size: 1.2em;
  }

  h3 {
    font-size: 1em;
  }

  h4 {
    font-size: 1em;
  }

  h5 {
    font-size: 1em;
  }

  p {
    font-size: 1em;
  }
}

@media all and (max-width: 1300px) and (min-width: 701px) {
  h1 {
    font-size: 2.2em;
  }

  h2 {
    font-size: 1.2em;
  }

  h3 {
    font-size: 1em;
  }

  h4 {
    font-size: 1em;
  }

  h5 {
    font-size: 1em;
  }

  p {
    font-size: 1em;
  }
}

@media all and (max-width: 700px) and (min-width: 300px) {
  h1 {
    font-size: 2.4em;
  }

  h2 {
    font-size: 1.2em;
  }

  h3 {
    font-size: 1.1em;
  }

  h4 {
    font-size: 1em;
  }

  h5 {
    font-size: 1em;
  }

  p {
    font-size: 1em;
  }
}