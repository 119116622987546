.portfolio-container {
    display: flex;
    flex-direction: column;
}

.portfolio-nav-container {
    display: flex;
    flex-direction: row;
}

.portfolio-nav-button {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    margin: 12px 12px 10px 10px;
    padding: 2px 20px 2px 2px;
    appearance: none;
    -webkit-appearance: none;
    outline: 0;
    cursor: pointer;
    background: transparent;
    border: none;
    color: rgb(209, 209, 209);

        &:focus {
            appearance: none;
            -webkit-appearance: none;
            outline: 0;
            transition: all .2s ease-in-out;
        }

        &:hover {
            appearance: none;
            -webkit-appearance: none;
            outline: 0;
            transform: scale(1.05);
            transition: all .2s ease-in;
            color: white;
            text-shadow: 0 4px 8px rgba(0, 0, 0, 0.377), 0 3px 6px rgba(0, 0, 0, 0.349);
        }

        &:active {
            appearance: none;
            -webkit-appearance: none;
            outline: 0;
            transition: all .2s ease-in-out;
            color: white;
        }
}

.selected {
    color: white;
    transition: all .2s ease-in-out;
    transform: scale(1.05);
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.377), 0 3px 6px rgba(0, 0, 0, 0.349);

    button {
        fill: white;
    }
}

.selected-vid {
    transition: transform .2s ease-in-out;
    transform: scale(1.05);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.534), 0 4px 6px rgba(0,0,0,0.23);
    border-bottom: 3px solid white;
}

.list-style-icon-container {
    display: flex;
    margin-left: 10px;

    button {
        fill: rgb(209, 209, 209);

        &:hover {
            appearance: none;
            -webkit-appearance: none;
            outline: 0;
            transform: scale(1.05);
            transition: all .2s ease-in-out;
            fill: white;
        }
    }

    .selected {
        fill: white;
    }
}

.portfolio-main-container {
    display: flex;
    flex-direction: column;
    max-height: 700px;
    height: 600px;
}

.portfolio-player-container {
    width: 640px;
    height: 360px;
    min-height: 360px;
    min-width: 640px;
    margin-top: 4px;
    overflow: hidden;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.226), 0 3px 6px rgba(0,0,0,0.23);

    iframe {
        height: 360px;
        width: 640px;
        min-height: 360px;
        min-width: 640px;
    }
}

.vid-title-container {
    padding: 15px 0px 0px 5px;
    text-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    margin: 0;
}

.vid-roles-container {
    padding: 0px 0px 15px 5px;
    min-height: 34px;

    p {
        margin: 0;
        padding: 0;
        text-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    }
}

.player-border {
    margin: 0px 0px 10px 4px;
    max-height: 1px;
    max-width: 630px;
    border-bottom: 1px solid rgb(209, 209, 209);
    ;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.534), 0 3px 6px rgba(0,0,0,0.23);
}

.portfolio-vids-container {
    max-width: 640px;
    position: relative;
}

.portfolio-list-container {
    display: flex;
    margin-left: 4px;
    overflow-x: scroll;
    max-width: 620px;
    align-content: center;
    -webkit-overflow-scrolling: touch;
    transition: all .2s ease-in-out;
    will-change: transform;
}

.portfolio-list-container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.left-button {
    position: absolute;
    left: -40px;
    top: 50px;
}

.right-button {
    position: absolute;
    right: -40px;
    top: 50px;
}

.left-button-btn, .right-button-btn {
    appearance: none;
    -webkit-appearance: none;
    outline: 0;
    cursor: pointer;
    transition: all .2s ease-in-out;
    background: transparent;
    border: none;
    color: rgb(209, 209, 209);

        &:focus {
            appearance: none;
            -webkit-appearance: none;
            outline: 0;
            transition: all .2s ease-in-out;
        }

        &:hover {
            appearance: none;
            -webkit-appearance: none;
            outline: 0;
            transform: scale(1.05);
            transition: all .2s ease-in-out;
            color: white;
            text-shadow: 0 4px 8px rgba(0, 0, 0, 0.377), 0 3px 6px rgba(0, 0, 0, 0.349);
        }

        // &:active {
        //     appearance: none;
        //     -webkit-appearance: none;
        //     outline: 0;
        //     transition: all .2s ease-in-out;
        //     color: white;
        // }
}

.portfolio-list-item {
    flex: 0 0 auto;
    width: 180px;
    max-width: 180px;
    height: calc(180px * (9/16));
    margin: 10px 10px 10px 5px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.534), 0 3px 5px rgba(0,0,0,0.23);
    transition: all .2s ease-in-out;
    will-change: transform;

    iframe {
        height: 100%;
        width: 100%;
        pointer-events: none;
    }

    &:hover {
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
        outline: 0;
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        -webkit-transition: all .2s ease-in;
        -o-transition: all .2s ease-in;
        transition: all .2s ease-in;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.534), 0 4px 6px rgba(0,0,0,0.23);
    }
}

.project-list {
    // max-height: 500px;
    height: 100%;
    overflow-y: auto;

    ul {
        margin:0;
        padding: 0;
        text-indent: 0;
        list-style-type: 0;
    }
}

.project-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 500px;
    max-height: 90px;
    margin: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.534), 0 2px 4px rgba(0,0,0,0.23);
    will-change: transition;

    iframe {
        pointer-events: none;
        // width: 100%;
        max-height: 100%;
        width: auto;
        max-width: 160px;
    }

    &:hover {
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
        outline: 0;
        -webkit-transition: all .2s ease-in;
        -o-transition: all .2s ease-in;
        transition: all .2s ease-in;
        box-shadow: 0 5px 8px rgba(0, 0, 0, 0.534), 0 5px 8px rgba(0,0,0,0.23);
    }
}

.project-list-item-selected {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 500px;
    max-height: 500px;
    height: auto;
    margin: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.534), 0 2px 4px rgba(0,0,0,0.23);
    will-change: transition;

    iframe {
        pointer-events: auto;
        // width: 100%;
        max-height: 300px;
        height: 290px;
        width: 100%;
        max-width: 100%;
    }

    &:hover {
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
        outline: 0;
        -webkit-transition: all .2s ease-in;
        -o-transition: all .2s ease-in;
        transition: all .2s ease-in;
        box-shadow: 0 5px 8px rgba(0, 0, 0, 0.534), 0 5px 8px rgba(0,0,0,0.23);
    }
}

.project-list-vid-selected {
    width: 100%;
    height: 290px;
    max-height: 300px;
    margin-top: 10px;
}

.list-title-container {
    margin: 12px 0px 2px 12px;
    max-width: 300px;

    p {
        font-size: 1.1em;
        font-weight: bold;
        color: white;
        margin: 0;
        padding: 0;
    }
}

.list-roles-container {
    margin: 2px 0px 6px 12px;
    min-height: 12px;
    
    p {
        margin: 0;
        padding: 0;
    }
}