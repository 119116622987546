@media all and (max-width: 1100px) and (min-width: 701px) {
    .contact-container {
        justify-content: flex-start;
        align-items: center;
        padding: 0;
    }
}

@media all and (max-width: 700px) and (min-width: 300px) {
    .contact-container {
        height: 80vh;
        width: 100vw;
        justify-content: flex-start;
        align-items: center;
        padding: 40px 0 0 0;
    }

    input, textarea {
        font: inherit;
        font-size: 14px;
        margin: 20px 10px 5px;
        width: 290px;
    }

    textarea {
        padding: 5px 0px 0px 6px;
    }
}