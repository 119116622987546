@media all and (max-width: 1100px) and (min-width: 701px) {
    .about-container {
        justify-content: flex-start;
        align-items: center;
        padding: 0;
    }

    .client-logos-container {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}

@media all and (max-width: 700px) and (min-width: 300px) {
    .about-container {
        height: 90vh;
        width: 100vw;
        justify-content: flex-start;
        align-items: center;
        padding: 12px 0 0 0;
    }

    .bio-container {
        width: 85%;
        overflow: hidden;
        font-size: 14px;
    }

    .client-logos-container {
        margin-top: 20px;
        width: 100%;
        overflow: hidden;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}