.about-container {
    display: flex;
    flex-direction: column;
    flex: 3;
    justify-content: center;
    align-items: flex-start;
    padding-left: calc(1vw + 125px);

    p {
        max-width: 500px;
    }
}

.bio-container {
    display: flex;
    flex-direction: column;
    color: white;
}

.client-logos-container {
    display: flex;
    width: 100%;
    align-content: center;
}

.client-logo {
    display: flex;
    margin-right: 2vw;
    overflow: hidden;
    width: 138px;
    height: 90px;

    iframe {
        max-height: 90px;
        max-width: 150px;
    }
}