@media all and (max-width: 1100px) and (min-width: 701px) {
    .home-container {
        position: static;
        display: flex;
        flex-direction: column;
    }
    
    .home-player-container {
        width: 75vw;
        height: auto;
        position: relative;
        padding-top: 56.25%;
        overflow: hidden;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.534), 0 3px 6px rgba(0,0,0,0.23);
    
        iframe {
            border: 0;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
        }
    }
    
}
  
// @media all and (max-width: 700px) and (min-width: 300px) {
    
// }