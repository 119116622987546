.contact-container {
    display: flex;
    flex: 3;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 125px;
}

.contact-header {
    display: flex;
    flex-direction: column;
}

.contact {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.contact-form {
    border-radius: 3px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 3px 8px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.form-submit-button {
    font-size: 14px;
    margin: 15px;
    padding: 10px 25px;
    appearance: none;
    -webkit-appearance: none;
    outline: 0;
    cursor: pointer;
    transition: all .2s ease-in-out;
    background: transparent;
    border: 1px solid white;
    border-radius: 2px;
    color: white;

    &:focus {
        appearance: none;
        -webkit-appearance: none;
        outline: 0;
        transition: all .2s ease-in-out;
    }

    &:hover {
        appearance: none;
        -webkit-appearance: none;
        outline: 0;
        transform: scale(1.05);
        transition: all .2s ease-in;
        background: black;
    }

    &:active {
        appearance: none;
        -webkit-appearance: none;
        outline: 0;
        transition: all .2s ease-in-out;
    }
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    font: inherit;
    font-size: inherit;
    transition: all 0.3s ease-in-out;
}

input, textarea {
    font: inherit;
    font-size: 14px;
    margin: 28px 25px 10px;
    padding: 20px 0px 5px 5px;
    width: 375px;
    display: block;
    border: none;
    transition: all 0.3s ease-in-out;
    resize: none;
    overflow: hidden;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    color: white;

    &:focus::-webkit-input-placeholder {
        font: inherit;
        opacity: 1;
    }
}

input {
    border-bottom: 1px solid white;

    &:focus {
        box-shadow: none;
        outline: none;
        opacity: 1;

        &::-webkit-input-placeholder {
            transform: translateY(-20px);
            visibility: visible !important;
            opacity: 1;
        }
    } 
}

input[type="text"], input[type="email"], input[type="textarea"] {
    background: transparent;
}

textarea {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    padding: 5px 0px 0px 6px;
    background: transparent;
    border: 1px solid white;
}