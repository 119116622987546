@media all and (max-width: 1100px) and (min-width: 701px) {
    .mobile-header {
        margin-left: 8vw;
        margin-bottom: 20px;
        margin-top: 20px;
        font-size: 1.4em;
    }

    .layout-container {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 100vw;
        grid-template-columns: 100vw;
        -ms-grid-rows: 30vh 80vh 10vh;
        grid-template-rows: 30vh 60vh 10vh;
        grid-template-areas:
        "nav"
        "page"
        "footer";
    }

    .page {
        position: inherit;
        height: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}
  
@media all and (max-width: 700px) and (min-width: 300px) {

    .mobile-header {
        margin-top: 80px;
        margin-bottom: 20px;
        padding: 10px 30px;
        font-size: 2em;
        text-shadow: 0 2px 8px rgba(0, 0, 0, 0.534), 0 8px 8px rgba(0, 0, 0, 0.452);
        color: white;
    }

    .layout-container {
        display: block;
        height: 100%;
        width: 100vw;
    }

    .page-container {
        display: none;
    }

    .mobile-page-container {
        display: inherit;
        height: 100vh;
        width: 100vw;
        padding-top: 20px;
        -webkit-box-flex: 0;
        -ms-flex: 0 auto;
        flex: 0 auto;
        -webkit-transition: all .5 ease-in-out;
        -o-transition: all .5 ease-in-out;
        transition: all .5 ease-in-out;
        background: rgb(77,77,77);
        background: -o-radial-gradient(circle, rgba(57,57,57,1) 0%, rgba(0,0,0,1) 100%);
        background: radial-gradient(circle, rgba(57,57,57,1) 0%, rgba(0,0,0,1) 100%);
    }
}