.success-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50vh;
}

.success-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-left: 2vw;
}

.success-top {
    margin-bottom: 4vh;

    p {
        margin: 0;
        font-size: 2em;
        font-weight: bold;
        color: white;
        text-shadow: 0 6px 12px rgba(0,0,0,0.30), 0 6px 12px rgba(0,0,0,0.22);
    }
}

.success-bottom {
    margin-bottom: 14vh;

    p {
        margin: 6px 0px 6px 0px;

        a {
            font-weight: bold;
            color: white;
            transition: all .1s ease-in-out;

            &:hover {
                text-shadow: 0 6px 12px rgba(0,0,0,0.30), 0 6px 12px rgba(0,0,0,0.22);
            }
        }
    }
}