.layout-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 30vw calc(1vw + 50px) 55vw;
    grid-template-columns: 30vw calc(1vw + 50px) 55vw;
    -ms-grid-rows: 80vh 10vh;
    grid-template-rows: 100vh;
    grid-template-areas: "nav  .  page";
}

.page-container {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
    grid-area: page;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.mobile-page-container {
    display: none;
}

.page {
    position: absolute;
    width: 50%;
}
  
.page-enter {
    opacity: 0;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.page-enter-active {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

.page-exit {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.page-exit-active {
    opacity: 0;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

.cursor-pointer {
    cursor: default;
}

.title-enter {
    opacity: 0;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
}

.title-enter-active {
    opacity: 0;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.title-exit {
    opacity: 0;
}

.title-exit-active {
    opacity: 1;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}



