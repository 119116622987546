@media all and (max-width: 1100px) and (min-width: 701px) {
    .portfolio-container {
        padding: 0;
    }

    .portfolio-player-container {
        width: 640px;
        height: 360px;
        max-height: 360px;
        margin-top: 4px;
        overflow: hidden;

        iframe {
            height: 360px;
            width: 100%;
        }
    }

    
}
  
@media all and (max-width: 700px) and (min-width: 300px) {
    .portfolio-container {
        height: 90vh;
        width: 100vw;
        justify-content: center;
        align-items: center;
        padding: 6px 0 0 0;
        margin-bottom: 4vh;
    }

    .portfolio-nav-container {
        margin: 0;
        padding: 0 0 0 20px;
        align-self: flex-start;
        max-width: 96vw;
        overflow: scroll;
    }

    .portfolio-nav-button {
        margin: 4px;
        padding: 4px 20px 4px 2px;
    }
    
    .portfolio-main-container {
       display: flex;
       align-items: center;
       width: 100%;
       height: 100%;
       flex: 4 0 auto;
    }

    .vid-title-container {
        padding: 5px 0px 0px 12px;
        align-self: flex-start;
        font-size: 1em;
        max-width: 300px;
    }
    
    .vid-roles-container {
        padding: 0px 0px 10px 12px;
        align-self: flex-start;
        font-size: .9em;
        margin-bottom: 10px;
    
        p {
            margin: 0;
            padding: 0;
        }
    }

    .player-border {
        width: 400px;
        margin: 4px;
    }

    .portfolio-player-container {
        width: 100vw;
        height: 100%;
        min-height: auto;
        min-width: 100%;
        max-height: 260px;
        box-shadow: none;

        iframe {
            height: auto;
            width: 100vw;
            min-height: 100%;
            min-width: 100vw;
        }
    }

    .portfolio-vids-container {
        max-height: 160px;
        position: static;
    }
    
    .portfolio-list-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: flex-start;
        flex-wrap: wrap;
        width: 90vw;
        max-height: 300px;
        flex: 0 auto;
        overflow-y: scroll;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .portfolio-list-item {
        // flex: 0 0 auto;
        width: 45%;
        height: 90px;
        margin: 3px;
    
        iframe {
            height: 100%;
        }
    }

    .project-list {
        margin-top: 10px;
    }

    .project-list-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 90vw;
        max-width: 400px;
    
        iframe {
            pointer-events: none;
        }
    
        &:hover {
            -moz-appearance: none;
            appearance: none;
            -webkit-appearance: none;
            outline: 0;
            -webkit-transition: all .2s ease-in;
            -o-transition: all .2s ease-in;
            transition: all .2s ease-in;
            box-shadow: 0 5px 8px rgba(0, 0, 0, 0.534), 0 5px 8px rgba(0,0,0,0.23);
        }
    }

    .project-list-item-selected {
        width: 100%;
        max-width: 500px;
        max-height: 320px;
        height: auto;
        margin: 6px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.534), 0 2px 4px rgba(0,0,0,0.23);
        will-change: transition;
    
        iframe {
            pointer-events: auto;
            // width: 100%;
            max-height: 280px;
            height: 260px;
            width: 100%;
            max-width: 100%;
        }
    
        &:hover {
            -moz-appearance: none;
            appearance: none;
            -webkit-appearance: none;
            outline: 0;
            -webkit-transition: all .2s ease-in;
            -o-transition: all .2s ease-in;
            transition: all .2s ease-in;
            box-shadow: 0 5px 8px rgba(0, 0, 0, 0.534), 0 5px 8px rgba(0,0,0,0.23);
        }
    }
    
    .project-list-vid-selected {
        width: 100%;
        height: 290px;
        max-height: 300px;
        margin-top: 10px;
    }

    .list-title-container {
        font-size: 14px;
        max-width: 90%;
    }

    .list-roles-container {
        font-size: 12px;
    }
}