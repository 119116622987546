.nav-container {
    grid-area: nav;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;

    a {
        white-space: nowrap;
        font-weight: bold;

        &:focus {
            -moz-appearance: none;
            appearance: none;
            -webkit-appearance: none;
            outline: 0;
            -webkit-transition: all .2s ease-in-out;
            -o-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
        }

        &:hover {
            -moz-appearance: none;
            appearance: none;
            -webkit-appearance: none;
            outline: 0;
            -webkit-transform: scale(1.03);
            -ms-transform: scale(1.03);
            transform: scale(1.03);
            -webkit-transition: all .125s ease-in;
            -o-transition: all .125s ease-in;
            transition: all .125s ease-in;
            text-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
            color: white;
        }

        &:active {
            -moz-appearance: none;
            appearance: none;
            -webkit-appearance: none;
            outline: 0;
            -webkit-transform: scale(1.03);
            -ms-transform: scale(1.03);
            transform: scale(1.03);
            -webkit-transition: all .2s ease-in-out;
            -o-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
        }
    }

    .page-link {
        font-size: 1.4em;
    }
}

.title {
    margin-bottom: 30px;
    max-width: 300px;
    text-align: right;

    p {
        margin: 0;
    }
}

.nav-footer {
    display: flex;
    margin-top: 40px;
}

.nav-footer-grow {
    transition: all .2s ease-in-out;
    margin-right: 10px;

    &:hover {
        transform: scale(1.1);
        
        a {
            color: white;
        }
    }
}

.active {
    transition: all .2s ease-in-out;
    color: white;
    -webkit-transform: scale(1.04);
    -ms-transform: scale(1.04);
    transform: scale(1.04);
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

// .active::before { 
//     content: "• ";
//     transition: all .2s ease-in-out;
// }