.footer-container {
    // grid-area: footer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50vw;

    &:nth-child() {
        flex: 1;
    }

    a {
        text-decoration: none;
        color: inherit;
        margin: 10px;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.534), 0 3px 6px rgba(0,0,0,0.23);
        border-radius: 16px;

        &:hover {
            box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
        }
    
        &:focus {
            color: inherit;
        }
    }
}

.footer-grow {
    transition: all .2s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
}

@media all and (max-width: 700px) and (min-width: 300px) {
    .footer-wrapper {
        padding: 10px;
        
    }
    .footer {
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        width: 100vw;
        border: none;
    }
}